import React from "react";
import SitemapContext from "./src/utils/SitemapContext";
import TranslatorContext from "./src/utils/TranslatorContext";

export const wrapRootElement = ({ element }) => (
  <SitemapContext.Provider value={JSON.parse(process.env.GATSBY_SITEMAP)}>
    <TranslatorContext.Provider value={JSON.parse(process.env.GATSBY_TRANSLATOR)}>
      {element}
    </TranslatorContext.Provider>
  </SitemapContext.Provider>
);